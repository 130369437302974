import { initSliderOnMobile } from './functions';
export function allSliders() {
  const defaultArgs = {
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    rows: 0,
  };

  let svgCode =
    '<svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<g>\n' +
    '    <path d="M6.13281 2V8" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '    <path d="M3.63281 6.53125L6.13281 8.53125L8.63281 6.53125" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '</g>\n' +
    '<path d="M11 10V10.6667C11 11.0203 10.8595 11.3594 10.6095 11.6095C10.3594 11.8595 10.0203 12 9.66667 12H2.33333C1.97971 12 1.64057 11.8595 1.39052 11.6095C1.14048 11.3594 1 11.0203 1 10.6667V10" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>\n' +
    '</svg>\n';

  $('.download-button').each(function () {
    let linkText = $(this).text();
    $(this).html(linkText + svgCode);
  });

  let cardsWithLinks = {
    arrows: true,
    infinite: false,
    variableWidth: true,
  };

  initSliderOnMobile('.slider-cards-with-links', cardsWithLinks, 'medium');

  let downloadsArgs = {
    arrows: false,
    infinite: false,
    variableWidth: true,
  };

  initSliderOnMobile('.download-block__pictures-slider', downloadsArgs);
  initSliderOnMobile('.download-block__members-slider', downloadsArgs);
  initSliderOnMobile('.download-block__logos-slider', downloadsArgs);

  let sliderArgs = {
    arrows: true,
    infinite: false,
    variableWidth: true,
  };

  initSliderOnMobile(
    '.mieter_journal_years .js-init-slider',
    sliderArgs,
    'large',
    true
  );
  initSliderOnMobile('.benefits-slider', sliderArgs, 'large', true);
  initSliderOnMobile('.your_topics__slider', sliderArgs, 'large', true);
  initSliderOnMobile(
    '.current_events_in_the_mediathek__slider',
    sliderArgs,
    'large',
    true
  );
  initSliderOnMobile('.our_tips__slider', sliderArgs, 'large', true);

  let teamCards = $.extend(defaultArgs, {});

  initSliderOnMobile('.js-team-cards', teamCards, 'medium', true);

  let latestRatgeberPostPreview = $.extend(defaultArgs, {});

  initSliderOnMobile(
    '.js-ratgeber-post-category-previews',
    latestRatgeberPostPreview,
    'medium',
    true
  );

  let cardsCategory = $.extend(defaultArgs, {});

  initSliderOnMobile('.js-category-cards', cardsCategory, 'medium', true);

  let cardSlider = {
    arrows: true,
    infinite: false,
    slidesToShow: 1,
  };

  initSliderOnMobile('.js-cards-slider', cardSlider, 'medium', true);

  let postPreviewsMediathek = {
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    variableWidth: true,
  };

  initSliderOnMobile(
    '.js-post-previews-mediathek',
    postPreviewsMediathek,
    'medium',
    true
  );

  function doubleSlider() {
    const sections = $('.slider-content');
    if (!sections) return false;
    sections.each(function (_, item) {
      const sliderText = $(item).find('.slider-content-texts');
      const sliderImage = $(item).find('.slider-content-images');
      const arrowsContainer = $(item).find('.slider-content-arrows');

      sliderText.slick({
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        appendArrows: arrowsContainer,
        asNavFor: sliderImage,
        variableWidth: true,
      });

      sliderImage.slick({
        slidesToShow: 1,
        fade: true,
        arrows: false,
        asNavFor: sliderText,
      });
    });
  }
  doubleSlider();

  $('.mediathek-categories-slider').each((idx, item) => {
    const arrowsContainer = $(item).next('.mediathek-categories-slider-arrows');
    $(item).slick({
      arrows: true,
      infinite: false,
      variableWidth: true,
      rows: 0,
      appendArrows: arrowsContainer,
    });
  });
}
