/* global ajax_object */
import Foundation from './inc/foundation';
import 'slick-carousel';

export function loadJournalsOnYear() {
  $('.js-select').on('change', function () {
    let section = $(this).closest('.mieter_journal_years');
    let sliderParent = section.find('.mieter_journal_years__cards');
    let selectedYear = $(this).val();
    if (selectedYear !== '') {
      $.ajax({
        type: 'POST',
        dataType: 'html',
        url: ajax_object.ajax_url,
        data: {
          action: 'journals_filter_by_year',
          year: selectedYear,
        },
        success: function (response) {
          sliderParent.html(response);
          if (sliderParent.hasClass('slick-initialized')) {
            sliderParent
              .removeClass('slick-initialized')
              .removeClass('slick-slider');

            updateSlider(sliderParent);
          }
          // eslint-disable-next-line
          FB3D_CLIENT_LOCALE.render(); // refresh click handler after AJAX
        },
      });
    }
  });

  function updateSlider(item) {
    let isSlider = $(item).hasClass('slick-initialized');
    if (Foundation.MediaQuery.is('large')) {
      if (isSlider) {
        $(item).slick('unslick');
      }
    } else if (!isSlider) {
      $(item).slick({
        infinite: false,
        prevArrow: $(item).closest('section').find('.slick-prev'),
        nextArrow: $(item).closest('section').find('.slick-next'),
        slidesToScroll: 1,
        variableWidth: true,
      });
    }
  }
}
