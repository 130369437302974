import { initSliderOnMobile, serializeFormData } from './inc/functions';
import Foundation from './inc/foundation';

(function () {
  const wrapper = $('.glossary__inner');
  if (!wrapper.length) return;

  const items = wrapper.find('.glossary-letter-wrapper');

  $(document).on('click', '.glossary__item .glossary-item__title', function () {
    const description = $(this).next('.glossary-item__description');
    $(this).toggleClass('is-active');
    const slider = $(this).closest('.slick-list');
    const props = {
      paddingTop: null,
      paddingBottom: null,
      marginTop: null,
      marginBottom: null,
      height: null,
    };

    description.slideToggle({
      step: (now, tween) => {
        if (!slider.length) return;
        if (props[tween.prop] === null) {
          props[tween.prop] = tween.now;
        }
        const delta = tween.now - props[tween.prop];
        props[tween.prop] = tween.now;
        slider.height(slider.height() + delta);
      },
    });
  });

  function divideByColumns() {
    if (items.closest('.glossary-column').length) return;

    wrapper.removeClass('height-calculated');
    const wrapperHeight = wrapper.outerHeight();
    let accumulatedHeight = 0;
    let half = null;

    items.each((idx, item) => {
      accumulatedHeight += $(item).outerHeight(true);
      if (accumulatedHeight >= wrapperHeight / 2 && half === null) {
        half = idx;
      }
    });

    items
      .filter((i) => i < half)
      .wrapAll('<div class="glossary-column"></div>');
    items
      .filter((i) => i >= half)
      .wrapAll('<div class="glossary-column"></div>');

    wrapper.addClass('height-calculated');
  }

  $(window).on('load changed.zf.mediaquery', function (e) {
    if (Foundation.MediaQuery.is('medium')) {
      if (e.type === 'load') {
        divideByColumns();
      }
    } else {
      if (items.closest('.glossary-column').length) {
        items.unwrap();
      }
    }
  });

  const glossaryArgs = {
    arrows: false,
    dots: true,
    appendDots: '.glossary-dots',
    adaptiveHeight: true,
    customPaging: function (slider, i) {
      const currentLetter = $(slider.$slides[i])
        .find('.glossary-letter')
        .text();
      return `<button>${currentLetter}</button>`;
    },
  };
  initSliderOnMobile(wrapper, glossaryArgs, 'medium');
  wrapper
    .on('destroy', function () {
      divideByColumns();
    })
    .on('beforeChange', function (e, slick, current, next) {
      const wrapperScroll = slick.$dots.scrollLeft();
      const dotPosition = slick.$dots.find('li').eq(next).position().left;
      if (dotPosition > $('.glossary-dots').width() || dotPosition < 0) {
        slick.$dots.animate({
          scrollLeft: wrapperScroll + dotPosition,
        });
      }
    });

  $('.glossary-search').on('submit', function (e) {
    e.preventDefault();
    const values = serializeFormData(this);
    const searchRegExp = new RegExp(
      '[\\s^]' + values.search + '[\\s\\.\\,$]',
      'i'
    );

    if (wrapper.hasClass('slick-initialized')) {
      wrapper.slick('slickUnfilter');
    }

    $('.glossary__item').each(function () {
      if ($(this).text().search(searchRegExp) < 0) {
        $(this).addClass('should-be-hidden').removeClass('should-be-visible');
      } else {
        $(this).addClass('should-be-visible').removeClass('should-be-hidden');
      }
    });
    items.each(function () {
      const $this = $(this);
      if ($this.find('.should-be-visible').length) {
        $this.fadeIn();
        $this.find('.should-be-visible').fadeIn();
        $this.find('.should-be-hidden').fadeOut();
      } else {
        $this.fadeOut();
      }
    });

    if (!wrapper.hasClass('slick-initialized')) {
      $('.glossary-column').each(function () {
        const $this = $(this);
        if ($this.find('.should-be-visible').length) {
          $this.fadeIn();
        } else {
          $this.fadeOut();
        }
      });
    } else {
      wrapper.slick('slickFilter', function () {
        return $(this).find('.should-be-visible').length > 0;
      });
    }
  });
})();
