import { PostsFilter } from './lib/posts-filter';

// eslint-disable-next-line no-unused-vars
class MediathekFilter extends PostsFilter {
  constructor(selector) {
    const controlClasses = {
      search: '.mediathek-search:not(.search-page-form)',
      category: '.mediathek-category',
      loadMore: '.load-more-mediathek',
    };

    const wrappersClasses = {
      categories: '.mediathek-chips',
      posts: '.js-mediatek-wrapper',
      postCard: '.mediathek-card',
    };
    super(selector, controlClasses, wrappersClasses);
    this.action = 'get_mediathek';
  }
}

new MediathekFilter('.mediathek-list');
