/* global ajax_object */
import { serializeFormData } from '../inc/functions';

export class PostsFilter {
  constructor(selector, filterControlsClasses, wrapperClasses) {
    this.filterParams = {
      search: '',
      categories: [],
      tags: [],
    };
    this.wrapper = $(selector);
    this.wrapperClasses = wrapperClasses;
    this.filterControls = {
      search: this.wrapper.find(filterControlsClasses.search),
      category: filterControlsClasses.category,
      loadMore: filterControlsClasses.loadMore,
      tag: filterControlsClasses.tag,
    };
    this.action = '';
    this.addActions();
  }
  addActions() {
    const that = this;
    that.filterControls.search.on('submit', function (e) {
      e.preventDefault();
      const values = serializeFormData(this);
      that.filterParams.search = values.search;
      if (values.order) {
        that.filterParams.order = values.order;
      }
      that.getPosts('form');
      const url = document.location;
      if (url.href.indexOf('?s=')) {
        const newURL =
          window.location.protocol +
          '//' +
          window.location.host +
          window.location.pathname +
          '?s=' +
          encodeURIComponent(values.search);
        history.pushState({}, '', newURL);
      }
      $('.search-query').text(values.search);
    });
    $(document).on('click', that.filterControls.category, function () {
      const categoryId = $(this).attr('data-category');
      if (!categoryId) {
        that.filterParams.categories.length = 0;
      } else {
        const idx = that.filterParams.categories.indexOf(categoryId);
        if (idx === -1) {
          that.filterParams.categories.push(categoryId);
        } else {
          that.filterParams.categories.splice(idx, 1);
        }
      }
      that.getPosts('category');
    });
    if (that.filterControls.tag) {
      $(document).on('click', that.filterControls.tag, function () {
        const tagId = $(this).attr('data-tag-id');
        const idx = that.filterParams.tags.indexOf(tagId);
        if (idx === -1) {
          that.filterParams.tags.push(tagId);
        } else {
          that.filterParams.tags.splice(idx, 1);
        }
        that.getPosts('tags');
      });
    }
    $(document).on('click', that.filterControls.loadMore, function () {
      const offset = that.wrapper.find(that.wrapperClasses.postCard).length;
      that.getPosts('loadMore', offset);
    });
  }
  getPosts(handler, offset = 0) {
    const that = this;

    if (that.doingAjax) return;

    $.ajax({
      url: ajax_object.ajax_url,
      type: 'GET',
      data: {
        action: that.action,
        params: that.filterParams,
        offset: offset,
      },
      beforeSend: function () {
        that.doingAjax = true;
      },
      success: function (resp) {
        if (handler === 'form' || that.filterParams.categories.length === 0) {
          that.wrapper
            .find(that.wrapperClasses.categories)
            .html(resp.categories);
        }
        if (handler === 'form' || handler === 'category') {
          that.wrapper.find(that.wrapperClasses.tags).html(resp.tags);
        }
        that.wrapper.find(that.wrapperClasses.posts).html(resp.posts);
        that.toggleActiveStates();
      },
      complete: function () {
        that.doingAjax = false;
      },
    });
  }

  toggleActiveStates() {
    const activeClass = 'is-active';

    this.wrapper
      .find(`${this.filterControls.category}`)
      .removeClass(activeClass);
    this.filterParams.categories.forEach((catId) => {
      this.wrapper
        .find(`${this.filterControls.category}[data-category="${catId}"]`)
        .addClass(activeClass);
    });
    if (this.filterParams.categories.length === 0) {
      this.wrapper
        .find(`${this.filterControls.category}:not([data-category])`)
        .addClass(activeClass);
    }

    this.wrapper
      .find(this.filterControls.tag + '[data-tag-id]')
      .removeClass(activeClass);
    this.filterParams.tags.forEach((tagId) => {
      this.wrapper
        .find(`${this.filterControls.tag}[data-tag-id="${tagId}"]`)
        .addClass(activeClass);
    });
  }
}
