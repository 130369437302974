import Foundation from './foundation';

export function serializeFormData(form) {
  const values = {};

  $.each($(form).serializeArray(), (i, field) => {
    values[field.name] = field.value;
  });

  return values;
}
export function initSliderOnMobile(
  selector,
  options = {},
  breakpoint = 'medium',
  setArrows = false
) {
  $(selector).each(function (idx, item) {
    $(window).on('load changed.zf.mediaquery', function () {
      let isSlider = $(item).hasClass('slick-initialized');
      if (Foundation.MediaQuery.is(breakpoint)) {
        if (isSlider) {
          $(item).slick('unslick');
        }
      } else if (!isSlider) {
        if (setArrows) {
          $.extend(options, {
            prevArrow: $(item).closest('section').find('.slick-prev'),
            nextArrow: $(item).closest('section').find('.slick-next'),
          });
        }
        $(item).slick(options);
      }
    });
  });
}

export function scrollToBlock(id) {
  const block = $(id);

  if (!block.length) return;

  let offset = $('.header').outerHeight();
  if (!offset) {
    offset = 0;
  }

  setTimeout(function () {
    let positionToScroll = '';
    if (innerWidth <= 390) {
      positionToScroll = $(id).offset().top - (offset - 100);
    } else {
      positionToScroll = $(id).offset().top - offset;
    }

    $('html,body').animate(
      {
        scrollTop: positionToScroll,
      },
      400
    );
  }, 500);
}
