///* eslint-disable */
import 'slick-carousel';
import 'jquery-match-height';
import 'select2';
import { allSliders } from './inc/sliders';
import './blog-filter';
import './mediathek-filter';
import './posts-loadmore';
import { loadJournalsOnYear } from './mieten_journal_filter_by_year';
import { serializeFormData } from './inc/functions';
import './glossary';
import Foundation from './inc/foundation';

$(document).foundation();
allSliders();

/* ---------------------------------------
	Image Popup
--------------------------------------- */
let $buttonZoomableImage = $('.zoomable-image button');
let $zoomableImage = $('.zoomable-image');
let $body = $('body');

$buttonZoomableImage.on('click', function () {
  $(this).hide();
  $('.social-icons').hide();
  $zoomableImage.addClass('zoomed');
  $body.addClass('scroll-fixed-popup');
});

function closePopup() {
  $buttonZoomableImage.show();
  $('.social-icons').show();
  $zoomableImage.removeClass('zoomed');
  $body.removeClass('scroll-fixed-popup');
}

$(window).click(function (event) {
  if ($(event.target).hasClass('zoomed')) {
    closePopup();
  }
});

/* ---------------------------------------
	Contact Overlay
--------------------------------------- */
let $overlayButtonClose = $('.js-overlay-button-close');
$('.js-button-contact').on('click', function () {
  $('.js-overlay-contact').addClass('active');
  $body.addClass('scroll-fixed-popup');

  $(window).click(function (event) {
    if ($(event.target).hasClass('active')) {
      closePopup();
    }
  });

  $overlayButtonClose.on('click', function () {
    closePopup();
  });

  function closePopup() {
    $('.js-overlay-contact').removeClass('active');
    $body.removeClass('scroll-fixed-popup');
  }
});

/* ---------------------------------------
	Overlay Newsletter
--------------------------------------- */
$('.js-overlay-newsletter').hide();
$overlayButtonClose.click(function () {
  hidePopup();
});

$(window).click(function (event) {
  if ($(event.target).hasClass('js-overlay-newsletter')) {
    hidePopup();
  }
});

function hidePopup() {
  $('.js-overlay-newsletter').hide();
  $body.removeClass('scroll-fixed-popup');
}

/* ---------------------------------------
	Overlay Search
--------------------------------------- */
$('.js-overlay-search').hide();
$overlayButtonClose.click(function () {
  hideSearchPopup();
});
$('.js-button-search').click(function () {
  showPopup('.js-overlay-search');
  $('.js-overlay-search').addClass('active');
});
$(window).click(function (event) {
  if ($(event.target).hasClass('js-overlay-search')) {
    hideSearchPopup();
    $('.js-overlay-search').remove('active');
  }
});

function hideSearchPopup() {
  $('.js-overlay-search').hide();
  $body.removeClass('scroll-fixed-popup');
}

function showPopup(popupClass) {
  $(popupClass).show();
  $body.addClass('scroll-fixed-popup');
}

/* ---------------------------------------
	Online Check
--------------------------------------- */
let $onlineCheck = $('.js-online-check');
$('.js-button-popup-online-check').on('click', function () {
  $onlineCheck.removeClass('active-popup-online-check');
  $(this).next().addClass('active-popup-online-check');
  $body.addClass('scroll-fixed-popup');
});

$overlayButtonClose.on('click', function () {
  $onlineCheck.removeClass('active-popup-online-check');
  $body.removeClass('scroll-fixed-popup');
});

$(window).click(function (event) {
  if ($(event.target).hasClass('js-online-check')) {
    $onlineCheck.removeClass('active-popup-online-check');
    $body.removeClass('scroll-fixed-popup');
  }
});
// ***********************************************************

$(document).on('keydown', function (e) {
  if (
    (e.metaKey || e.ctrlKey) &&
    String.fromCharCode(e.which).toLowerCase() === 'm'
  ) {
    $('.mesh').toggleClass('hide');
  }
});

$('.js-row').scroll(function () {
  if ($(this).scrollLeft() > 0) {
    $(this).addClass('scrolling');
  } else {
    $(this).removeClass('scrolling');
  }
});

/**
 * Add `is-active` class to menu-icon button on Responsive menu toggle
 * And remove it on breakpoint change
 */
$(window)
  .on('toggled.zf.responsiveToggle', function () {
    $('.menu-icon').toggleClass('is-active');
    $('body').toggleClass('lock');
  })
  .on('changed.zf.mediaquery', function () {
    $('.menu-icon').removeClass('is-active');
    $('body').removeClass('lock');
  });

$(document).on('click', function (e) {
  const burgerButton = $('.menu-icon');
  if (burgerButton.hasClass('is-active')) {
    if (!e.target.closest('.top-bar') && !e.target.closest('.menu-icon')) {
      burgerButton.click();
    }
  }
});

$('.submenu-toggle').each(function (_, item) {
  $(item).on('click', function () {
    $('.has-submenu-toggle').removeClass('active_sub_menu');
    if ($(this).attr('aria-expanded') === 'true') {
      $(item).closest('.menu-item').addClass('active_sub_menu');
    }
  });
});
/**
 * Close responsive menu on orientation change
 */
$(window).on('orientationchange', function () {
  setTimeout(function () {
    if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
      $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
    }
  }, 200);
});

$('.reviews-slider').each((idx, item) => {
  const arrowsContainer = $(item).next('.reviews-slider-arrows');
  $(item).slick({
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    rows: 0,
    appendArrows: arrowsContainer,
  });
});

$('.quotes-slider').each((idx, item) => {
  const arrowsContainer = $(item).next('.quotes-slider-arrows');
  $(item).slick({
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    appendArrows: arrowsContainer,
  });
});

$('.erfolgsgeschichten-posts-slider').each((idx, item) => {
  const arrowsContainer = $(item).next('.slider-posts-arrows');
  $(item).slick({
    arrows: true,
    infinite: true,
    slidesToShow: 4,
    appendArrows: arrowsContainer,
    infinity: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });
});

function startCounter() {
  $('.counter-block .grid-container').each(function (i, section) {
    if ($(section).isInViewport()) {
      if ($(section).hasClass('js-active')) return;

      $(section).addClass('js-active');
      $(section)
        .find('.counter-item_number > span')
        .each(function (i, counter) {
          $(counter).countUp();
        });
    }
  });
}

$.fn.isInViewport = function (whenElementWillSee) {
  if ($(this).length) {
    let x = whenElementWillSee ? whenElementWillSee : 200;
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop() - x;
    var viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  }
  return 0;
};

$(window).on('scroll load', function () {
  startCounter();
});

$.fn.countUp = function () {
  let num = $(this).attr('data-initial');
  let decimal = 0;

  if (num === 0 || isNaN(parseFloat(num))) {
    return;
  }
  num = num.replace(',', '.');
  const split = num.toString().split('.');

  if (split[1]) {
    decimal = split[1].length;
  }

  $(this)
    .prop('counter', 0.0)
    .animate(
      {
        counter: num,
      },
      {
        duration: 500,
        step: function (now) {
          $(this).text(parseFloat(now).toFixed(decimal));
        },
        complete: function () {
          $(this).stop(true, true);
        },
      }
    );
};

function journalSelect() {
  const sections = $('.mieter_journal_years');
  if (!sections) return false;

  sections.each(function (_, section) {
    const select = $(section).find('.js-select'),
      placeholder = select.attr('data-placeholder');

    $(select).select2({
      minimumResultsForSearch: Infinity, //remove reach field
      dropdownParent: $(section).find('.select_wrap'),
      placeholder: placeholder,
      allowClear: false,
      closeOnSelect: true,
    });
  });
}

journalSelect();

function mediathekSelect() {
  const sections = $('.mediathek-list');
  if (!sections) return false;

  sections.each(function (_, section) {
    const select = $(section).find('.js-mediathek-select'),
      placeholder = select.attr('data-placeholder');

    $(select).select2({
      minimumResultsForSearch: Infinity, //remove reach field
      dropdownParent: $(section).find('.select-mediathek_wrap'),
      placeholder: placeholder,
      allowClear: false,
      closeOnSelect: true,
    });
  });
}

mediathekSelect();

loadJournalsOnYear();

$('.table-block, .hero-table').each(function () {
  let rowCount = $(this).find('.content-item tr').length;

  if (rowCount > 6) {
    $(this).find('tbody tr:gt(5)').hide();
    $(this).find('.table-block__more').show();
    $(this).find('.table-block__less').hide();
  }
});

$('.table-block__more').on('click', function () {
  $(this)
    .parent('.table-block__buttons')
    .siblings('table')
    .find('.content-item tr')
    .show();

  $(this).hide();
  $(this).parent('.table-block__buttons').find('.table-block__less').show();
});

$('.table-block__less').on('click', function () {
  $(this)
    .parent('.table-block__buttons')
    .siblings('table')
    .find('.content-item tr:gt(5)')
    .hide();

  $(this).hide();
  $(this).parent('.table-block__buttons').find('.table-block__more').show();
});

$('.contact_cards .card').each(function () {
  let lastEl = $(this).find('>:last');
  let lastChild_Button = lastEl.find('a.button');
  if (lastChild_Button) {
    lastChild_Button.closest('p').addClass('has_button');
  }
});

function sameHeightFor_your_topics_section() {
  let sections = $('.your_topics');
  if (sections.length === 0) return false;
  sections.each(function (_, section) {
    $(section).find('.card h5').matchHeight();
  });
}

sameHeightFor_your_topics_section();

// $('a[href*="#"]').on('click', function (e) {
//   const $this = $(this);
//   if (
//     $this.closest('[data-tabs]').length ||
//     $this.closest('[data-accordion]').length
//   )
//     return;
//   const href = $this.attr('href');
//   if (href.indexOf('#') === 0) {
//     e.preventDefault();
//     scrollToBlock(href);
//   } else {
//     let split = href.split('#');
//     let location = window.location.href.replace('#', '');
//     if (location === split[0]) {
//       e.preventDefault();
//       scrollToBlock('#' + split[1]);
//     }
//   }
// });
// //Smooth scroll to another page
// let locationHash = window.location.hash;
// if (locationHash) {
//   window.location.hash = '';
//   scrollToBlock(locationHash);
// }
// Select all links with hashes
$('a[href*="#"]')
  .not('[href="#"]')
  .not('[href="#0"]')
  .not('[role="tab"]')
  .click(function (event) {
    // On-page links
    if (
      location.pathname.replace(/^\//, '') ==
        this.pathname.replace(/^\//, '') &&
      location.hostname == this.hostname
    ) {
      // Figure out element to scroll to
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
      // Does a scroll target exist?
      if (target.length) {
        // Only prevent default if animation is actually gonna happen
        event.preventDefault();
        $('html, body').animate(
          {
            scrollTop: target.offset().top,
          },
          1000,
          function () {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(':focus')) {
              // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            }
          }
        );
      }
    }
  });

function teamBlockMobileShowMore() {
  let sections = $('.team');
  if (!sections) return false;
  sections.each(function (_, item) {
    const cells = $(item).find('.card_cell');
    const btn = $(item).find('.show_more_btn');
    $(window).on('load changed.zf.mediaquery', function () {
      if (Foundation.MediaQuery.is('small down')) {
        cells.slice(2).wrapAll('<div class="team__wrapper"></div>');
        let wrapper = $(item).find('.team__wrapper');
        wrapper.slideUp();
        showMore($(item), wrapper, true);
      } else {
        let wrapper = cells.slice(2).closest('.team__wrapper');
        wrapper.contents().unwrap();
        showMore($(item), wrapper, false);
        if (btn.hasClass('active')) btn.removeClass('active');
      }
    });
  });

  function showMore(section, wrapper, on) {
    let btn = section.find('.show_more_btn');
    const showMoreText = btn.attr('data-show');
    const hiddenText = btn.attr('data-hidden');
    if (on) {
      btn.on('click', function () {
        if ($(this).hasClass('active')) {
          wrapper.slideUp();
          $(this).removeClass('active');
          $(this).text(showMoreText);
        } else {
          wrapper.slideDown();
          $(this).addClass('active');
          $(this).text(hiddenText);
        }
      });
    } else {
      btn.off('click');
      btn.text(showMoreText);
    }
  }
}

teamBlockMobileShowMore();

//header scroll down/up=====
function headerScrollUp_scrollDown() {
  let lastScrollTop = 0;
  const header = $('.header');
  let headerHeight = header.outerHeight() + parseFloat(header.css('top'));
  const firstSection = header.next().find('section:first-child');
  let heightOfFirstSection = firstSection.outerHeight();

  function handlerScroll() {
    let scrollTop = $(window).scrollTop();
    if (
      scrollTop > lastScrollTop &&
      scrollTop >= heightOfFirstSection - headerHeight
    ) {
      if ($(window).width() < 641 || $(window).width() > 1024) {
        header.addClass('hidden_header');
      }
    }
    if (scrollTop < lastScrollTop || scrollTop < 0 || scrollTop === 0) {
      header.removeClass('hidden_header');
    }

    lastScrollTop = scrollTop;
  }

  $(window).resize(function () {
    heightOfFirstSection = firstSection.outerHeight();
    headerHeight = header.outerHeight() + parseFloat(header.css('top'));
  });
  $(window).scroll(handlerScroll);
}

headerScrollUp_scrollDown();

function receiveMessage(event) {
  if (typeof event.data !== 'string') return;

  var message = event.data.split(':');
  var eventName = message[0];
  var iframes,
    len,
    i = 0;

  // Domains to accept post messages from:

  var senderDomains = ['https://login.mieterverein-hamburg.de'];

  // Fix .indexOf in IE8
  if (!Array.prototype.indexOf) {
    Array.prototype.indexOf = function (obj, start) {
      for (var i = start || 0, j = this.length; i < j; i++) {
        if (this[i] === obj) {
          return i;
        }
      }
      return -1;
    };
  }

  if (senderDomains.indexOf(event.origin) !== -1 && eventName === 'resize') {
    iframes = document.getElementsByTagName('iframe');
    len = iframes.length;
    for (; i < len; i++) {
      if (
        (iframes[i].contentWindow || iframes[i].documentWindow) == event.source
      ) {
        iframes[i].style.height = +message[1] + 8 + 'px';
        return;
      }
    }
  }
}

if (window.addEventListener) {
  window.addEventListener('message', receiveMessage, false);
} else if (window.attachEvent) {
  window.attachEvent('onmessage', receiveMessage);
}

function ready(callback) {
  if (document.readyState != 'loading') {
    callback(event);
  } else {
    document.addEventListener('DOMContentLoaded', callback);
  }
}

ready(() => {
  let queryHash = window.location.hash;
  let iframe = document.getElementById('responsive-iframe');
  if (!iframe) return;
  iframe.src = iframe.src + queryHash;
});

function adaptiveHeightForBenefitsSlider() {
  const sections = $('.benefits');
  if (sections.length === 0) return;
  sections.each(function (_, item) {
    const benefitFirstCardOfCardsBody = $(item).find(
      '.benefits__cards_body .card:first-child'
    );
    $(window).on('load changed.zf.mediaquery', function () {
      if (Foundation.MediaQuery.is('medium down')) {
        setTimeout(function () {
          benefitFirstCardOfCardsBody.matchHeight();
        }, 1000);
        $(item).addClass('benefit_same_height');
        benefitFirstCardOfCardsBody.addClass('benefit_same_height');
      } else {
        $(item).removeClass('benefit_same_height');
        benefitFirstCardOfCardsBody.removeClass('benefit_same_height');
      }
    });
  });
}

adaptiveHeightForBenefitsSlider();

function invertNavBarButtons() {
  $(window).on('load scroll resize changed.zf.mediaquery', function () {
    if (Foundation.MediaQuery.is('large')) {
      let blueButton = $(`[data-invert='blue']`);
      let darkButton = $(`[data-invert='dark']`);
      let buttonOffset = false;

      const blueSections = $('main > section').filter(
        (idx, item) => $(item).css('background-color') === 'rgb(1, 69, 172)'
      );

      const darkSections = $('main > section, .footer').filter(
        (idx, item) => $(item).css('background-color') === 'rgb(1, 20, 45)'
      );

      if (blueButton.length || darkButton.length) {
        buttonOffset = blueButton.get(0).getBoundingClientRect();
        buttonOffset = darkButton.get(0).getBoundingClientRect();
      }

      for (let item of blueSections) {
        const sectionPos = item.getBoundingClientRect();
        let sectionTop = sectionPos.top;
        let sectionBottom = sectionPos.bottom;
        let sectionPaddingTop = parseFloat(getComputedStyle(item).paddingTop);
        if (
          sectionTop - (sectionPaddingTop + 70) < buttonOffset.top &&
          sectionBottom > buttonOffset.bottom
        ) {
          blueButton.addClass('invert_color_button');
          return;
        }

        blueButton.removeClass('invert_color_button');
      }

      for (let item of darkSections) {
        const sectionPos = item.getBoundingClientRect();
        let sectionTop = sectionPos.top;
        let sectionBottom = sectionPos.bottom;
        let sectionPaddingTop = parseFloat(getComputedStyle(item).paddingTop);
        if (
          sectionTop - (sectionPaddingTop - 20) < buttonOffset.top &&
          sectionBottom > buttonOffset.bottom
        ) {
          darkButton.addClass('invert_color_button');
          return;
        }

        darkButton.removeClass('invert_color_button');
      }
    }
  });
}

invertNavBarButtons();

function stickyAudioRoad() {
  const block = $('.mediathek-audio');
  if (block.length === 0) return;
  setTimeout(function () {
    const blockHeight = block[0].offsetHeight;
    $('body').css('padding-bottom', blockHeight);
  }, 500);
}

$(window).on('load', function () {
  stickyAudioRoad();
});

let values = {};
$('.search-page-form').on('submit', function (e) {
  e.preventDefault();
  /* global ajax_object */
  values = serializeFormData(this);
  $.ajax({
    url: ajax_object.ajax_url,
    type: 'GET',
    data: {
      action: 'get_mediathek',
      params: values,
      is_search: true,
    },
    success: function (resp) {
      $('.js-mediatek-wrapper').html(resp.posts);
    },
  });
});

$(document).on('click', '.search-load-more', function (e) {
  e.preventDefault();
  const offset = $('.js-mediatek-wrapper .mediathek-card').length;
  if (!values.search) {
    values.search = $(this).attr('data-search');
  }
  if (!values.order) {
    values.order = 'desc';
  }
  $.ajax({
    url: ajax_object.ajax_url,
    type: 'GET',
    data: {
      action: 'get_mediathek',
      params: values,
      is_search: true,
      offset,
    },
    success: function (resp) {
      $('.js-mediatek-wrapper').html(resp.posts);
    },
  });
});
