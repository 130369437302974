import { PostsFilter } from './lib/posts-filter';
class PresseFilter extends PostsFilter {
  constructor(selector) {
    const controlClasses = {
      search: '.blog-search',
      category: '.posts-categories__item',
      loadMore: '.load-more-posts',
      tag: '.posts-tags__tag',
    };

    const wrappersClasses = {
      categories: '.posts-categories',
      tags: '.posts-tags__wrapper',
      posts: '.js-posts-wrapper',
      postCard: '.post-card',
    };
    super(selector, controlClasses, wrappersClasses);

    this.action = 'get_posts';
  }
}

new PresseFilter('.blog-section');
